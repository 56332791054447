import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { ToastrService } from 'ngx-toastr';

import {
  tap,
  distinctUntilChanged,
  switchMap,
  startWith
} from 'rxjs/operators';

import { EMPTY, forkJoin,throwError } from 'rxjs';
import { SharedParameterService } from 'src/app/services/sharedparameterservice.service';
import { TranslateService } from '@ngx-translate/core';
interface DataArray
  { name: string; code: string; }  

@Component({
  selector: 'app-new-customer-setup',
  templateUrl: './new-customer-setup.component.html',
  styleUrls: ['./new-customer-setup.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class NewCustomerSetupComponent implements OnInit, OnDestroy {  
  _getDetails:any = [];
  _getCustomerInfobySoldToId:any = [];
  @Input() disableForm: any;
  @Input() isShipTo: any;
  @Output() closeFormEvent = new EventEmitter();
  @Output() setActiveItem = new EventEmitter();
  availableCars:any = [];
    selectedCar:any ;
  InvalidEmailsList = '';
  InvalidEmailsListafetyData = '';
  InvalidEmailsListcofA = '';
  InvalidEmailsListorderAcknowledgement = '';
  InvalidEmailsListadvanceShippingNotification = '';
  materialNumberList: any = [];
  isExternalUser = true;
  customerDetails: UntypedFormGroup;
  creditFormType: string = 'NONE';
  submitted: boolean = false;
  disableTankRegBillTo: boolean = true;
  disableTankRegSoldTo: boolean = true;
  disableTankRegPayerAddress: boolean = true;
  disableTankRegShipToAddress: boolean = true;
  dropdown = [
    { name: 'Yes', code: '1' },
    { name: 'No', code: '2' },
    { name: 'NA', code: '0' },
  ];
  
  paperworkDD:DataArray[] = [
    { name: 'Yes', code: '1' },
    { name: 'No', code: '2' },
  ];
  taxDropdown = [
    { name: 'Tax Exempt', code: '1' },
    { name: 'Taxable', code: '2' },
  ];
  PaymentTerms: any = [];
  CurrencyOfSales: any = [];
  Languages: any = [];
  IncoTerms: any = [];
  ShippingConditions: any = [];
  BusinessUnits: any = [];
  fopId = sessionStorage.getItem('fopId');
  currentFop:any;
  endUserFop: any = sessionStorage.getItem('enduserFop');
  fopCountry: any;
  fopTypeId: any;
  fopBySoldToInfo: any = {
    soldTo_Address: [],
    shipTo_Address: [],
    billTo_Address: [],
    payer_Address: [],
  };
  savedFormData: any;
  fopInitiationForm: any;
  showDownload = false;

  showspinnner_GetMaterial: boolean = false;
  showspinnner_SavedNew: boolean = false;
  showspinnner_FormData: boolean = false;
  showspinnner_ForDropdown: boolean = false;
  showspinnner_Initializetab: boolean = false;

  isBillToAddressSame: UntypedFormControl = new UntypedFormControl(false);
  isShipToAddressSame: UntypedFormControl = new UntypedFormControl(false);
  isPayerAddressSame: UntypedFormControl = new UntypedFormControl(false);
  saveValue = '';
  fopReadyToGO = sessionStorage.getItem('fopReadyToGO');
  taxCountryFlag = '';
  materialList = [];
  @Input() enableTaxTab: boolean = false;
  endUserBusiness: any;
  selectedisOrderAcknowledgement!: DataArray;
  currentLang: string = '';

  constructor(
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private _fopHttpService: FopHttpService,
    private _sharedParameter: SharedParameterService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    console.log(this.enableTaxTab, 'constructer', this.taxCountryFlag);
    this.isExternalUser = sessionStorage.getItem('isExternalUser') + '' === 'true';
    this.customerDetails = this.fb.group({
      isReqFiledsFilled: [false],
      fopId: [sessionStorage.getItem('fopId')],
      createdBy: [sessionStorage.getItem('userId')],
      requestedBy: [''],
      salesOrganization: [''],
      requestDate: [''],
      distributionChannel: [''],
      shippingPlant: [''],
      addOrChange: [''],
      division: ['01'],
      actionNeeded: [''],
      paymentTerms: [''],
      accountNumber: [''],
      projectAnnualSales: [''],
      soldTo: [''],
      currencyOfSales: [''],
      shipTo: [''],
      distributor: [''],
      billTo: [''],
      language: [''],
      payer: [''],
      incoTerms: [''],
      shippingConditions: [''],
      safetyDataSheet: [''],
      contact: [
        '',
        [
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      companyNameSoldTo: ['', Validators.required],
      addressLine1SoldTo: ['', Validators.required],
      addressLine2SoldTo: [''],
      citySoldTo: ['', Validators.required],
      stateSoldTo: ['', Validators.required],
      tankregSoldTo: [''],
      zipSoldTo: ['', Validators.required],
      countrySoldTo: ['', Validators.required],
      contactNameSoldTo: ['', Validators.required],
      phoneSoldTo: [
        '',
        [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      faxSoldTo: [''],
      emailSoldTo: ['', [Validators.required, Validators.email]],
      companyNameBillTo: ['', Validators.required],
      addressLine1BillTo: ['', Validators.required],
      addressLine2BillTo: [''],
      cityBillTo: ['', Validators.required],
      stateBillTo: ['', Validators.required],
      tankregBillTo: [''],
      zipBillTo: ['', Validators.required],
      countryBillTo: ['', Validators.required],
      contactNameBillTo: [''],
      phoneBillTo: [
        '',
        [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      faxBillTo: [''],
      poboxBillTo: [''],
      emailBillTo: ['', [Validators.required, Validators.email]],
      shipToAddress: this.fb.array([this.newShipToAddress()]),
      companyNamePayerAddress: ['', Validators.required],
      addressLine1PayerAddress: ['', Validators.required],
      addressLine2PayerAddress: [''],
      cityPayerAddress: ['', Validators.required],
      statePayerAddress: ['', Validators.required],
      tankregPayerAddress: [''],
      zipPayerAddress: ['', Validators.required],
      countryPayerAddress: ['', Validators.required],
      contactNamePayerAddress: [''],
      phonePayerAddress: [
        '',
        [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      faxPayerAddress: [''],
      emailPayerAddress: ['', [Validators.required, Validators.email]],
      isOrderAcknowledgement: ['', Validators.required],
      
      orderAcknowledgementEmail: [''],
      isCofA: ['', Validators.required],
      cofAEmail: [''],
      isSafetyDataSheet: ['', Validators.required],
      safetyDataSheetEmail: [''],
      isInvoices: ['1'],
      invoicesEmail: ['', Validators.required],
      isAdvanceShippingNotification: ['', Validators.required],
      advanceShippingNotificationEmail: [''],
      worldAccount: [''],
      elemica: [''],
      edi: [''],
      labelingRequirements: [''],
      businessUnit: [''],
      taxExemptStatus: ['', Validators.required],
      taxExemptCertificate: [''],
      taxExemptCertificateName: [''],
      taxInformation: this.fb.group({
        gstShipmentCertifcate: [''],
        gstCertificateDocument: [''],
        gstHstCertificateName: [''],
        qstShipmentCertifcate: [''],
        qstCertificateDocument: [''],
        qstCertificateName: [''],
        salesTaxCertifcate: [''],
        salesTaxCertificateDocument: [''],
        salesTaxCertificateName: [''],
        vat: [''],
        otherTaxIdentification: [''],
      }),
      materialDetails: this.fb.array([
        this.fb.group({
          id: [0],
          materialNumber: [''],
          materialName: [''],
        }),
      ]),

      eSolutionDetails: this.fb.array([
        this.fb.group({
          id: [0],
          fullName: [''],
          email: ['',  [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
          isedi:true
        }),
      ]), 

      eSolutionDetailsEDI: this.fb.array([
        this.fb.group({
          id: [0],
          fullName: [''],
          email: ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
          isedi:true
        }),
      ])


    });
    this.translate.stream('ALL_FOP').subscribe((resp) => {
      this.initializeTabItems();
      // this.showspinnner_Initializetab = true;
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initializeTabItems();

     //this.getMaterial1();    
    // this.getMasterDataForDropdown();

     this._getDetails = [
      this._fopHttpService.GetMaterils(this.fopId),
      this._fopHttpService.getMasterData(),
      this._fopHttpService.getAllDivisions(),
      this._fopHttpService.getNewCustomerSetupData(this.fopId)
     ];   
  
    forkJoin(this._getDetails).subscribe((res:any)=>{
      if (!res[0]['isError']) {
       this.materialNumberList = res[0];        
     } 

     if (!res[1]['isError']) {
        for (const dataObj of res[1].response) {
          this.setMasterDataObjects(dataObj);
        }       
      }

      if (!res[2]['isError']) {
        this.getMasterAllDivisions(res[2].response)        
      }

      if (!res[3]['isError']) {
        this.getSavedNewCustomerSetupData(res[3])        
      }

    });  

    this.isBillToAddressSameAsSoldTo();
    this.isShipToAddressSameAsSoldTo();
    this.isPayerAddressSameAsSoldTo();
 
    this.showspinnner_FormData = false;

    if (this.disableForm || this.fopReadyToGO === 'yes') {
      this.customerDetails.disable();
    }

    let tempCountry: any = sessionStorage.getItem('fopCountry');

    if (tempCountry) {
      this.taxCountryFlag = tempCountry;
    }
    let l_enduserFop:any=sessionStorage.getItem('enduserFop');
    l_enduserFop=l_enduserFop??'{}';
    let l_fop:any=sessionStorage.getItem('fop');
    l_fop=l_fop??'{}';
    let currentfop:any=(Object.keys(JSON.parse(l_enduserFop)).length===0)?l_fop:l_enduserFop;
    if(currentfop){
      this.endUserFop=currentfop;
      currentfop=JSON.parse(currentfop);
      if(currentfop.businessUnit=="AP"){
        this.endUserBusiness="AP";
      }
    }

    if (this.endUserFop) {      
      this.setFormDatawithExistingFop()
    }
    else{
      this.showspinnner_FormData = false;
    }
 
    if(this.showspinnner_FormData == false)
    {
    setTimeout(() => {
      this.spinner.hide();
      }, 13000);
    }
    
    console.log(this.enableTaxTab, 'ngoinit', this.taxCountryFlag);  
    this.currentLang = this.translate.currentLang;
  }

  setFormDatawithExistingFop(){
    this.endUserFop = JSON.parse(this.endUserFop);
      this.endUserBusiness = this.endUserFop.businessUnit;
      if(this.endUserBusiness =='AP' ){            
        if(this.customerDetails.get('isOrderAcknowledgement')?.value =='')
        this.customerDetails.patchValue({isOrderAcknowledgement:'1'});
        if(this.customerDetails.get('isAdvanceShippingNotification')?.value =='')
        this.customerDetails.patchValue({isAdvanceShippingNotification:'1'});       
       }
      this.customerDetails.get('isCofA')?.clearValidators();
      this.customerDetails.get('isSafetyDataSheet')?.clearValidators();
      this.fopTypeId = this.endUserFop.fopTypeId;

      this.fopCountry = this.endUserFop.fopCountry;
      if (this.endUserFop.fopBySoldToId === 'Yes') {
        this.isShipTo = false;
        this.getCustomerInfobySoldToId();  
      }
  }


  initializeTabItems() {
    this.dropdown = [
      { name: this.translate.instant('YES'), code: '1' },
      { name: this.translate.instant('NO'), code: '2' },
      { name: 'NA', code: '0' },
    ];
    this.paperworkDD = [
      { name: this.translate.instant('YES'), code: '1' },
      { name: this.translate.instant('NO'), code: '2' },
    ];
    this.taxDropdown = [
      { name: this.translate.instant('TAX_EXEMPT'), code: '1' },
      { name: this.translate.instant('TAXABLE'), code: '2' },
    ];
  }

  ngOnDestroy() {
    sessionStorage.setItem('coa', this.customerDetails.value.isCofA);
    sessionStorage.setItem('coaEmail', this.customerDetails.value.cofAEmail);
    sessionStorage.setItem(
      'asn',
      this.customerDetails.value.isAdvanceShippingNotification
    );
    sessionStorage.setItem(
      'asnEmail',
      this.customerDetails.value.advanceShippingNotificationEmail
    );
    this.onSubmit();    
  }

  newShipToAddress(existingShipTo?: any): UntypedFormGroup {
    return this.fb.group({
      id: [existingShipTo?.id ?? 0],
      companyNameShipToAddress: [
        existingShipTo?.companyNameShipToAddress ?? '',
        Validators.required,
      ],
      addressLine1ShipToAddress: [
        existingShipTo?.addressLine1ShipToAddress ?? '',
        Validators.required,
      ],
      addressLine2ShipToAddress: [
        existingShipTo?.addressLine2ShipToAddress ?? '',
      ],
      cityShipToAddress: [
        existingShipTo?.cityShipToAddress ?? '',
        Validators.required,
      ],
      stateShipToAddress: [
        existingShipTo?.stateShipToAddress ?? '',
        Validators.required,
      ],
      tankregShipToAddress: [
        existingShipTo?.tankregShipToAddress ?? '',
      ],
      zipShipToAddress: [
        existingShipTo?.zipShipToAddress ?? '',
        Validators.required,
      ],
      countryShipToAddress: [
        existingShipTo?.countryShipToAddress ?? '',
        Validators.required,
      ],
      contactNameShipToAddress: [
        existingShipTo?.contactNameShipToAddress ?? '',
        Validators.required,
      ],
      phoneShipToAddress: [
        existingShipTo?.phoneShipToAddress ?? '',
        [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      faxShipToAddress: [existingShipTo?.faxShipToAddress ?? ''],
      emailShipToAddress: [
        existingShipTo?.emailShipToAddress ?? '',
        [Validators.required, Validators.email],
      ],
    });
  }

  addShipToAddress() {
    this.shipToAddress.push(this.newShipToAddress());
    console.log(this.shipToAddress);
  }

  removeShipToAdd(i: number) {
    this.shipToAddress.removeAt(i);
  }

  incoTermChange(value: any) {
    sessionStorage.setItem('incoterm', value);
  }
  coaAsnChange(value: any, type: string) {
    sessionStorage.setItem(type, value);
  }

  getMaterial() { 
    // this.spinner.show();
    this._fopHttpService.GetMaterils(this.fopId).subscribe((response: any) => {
      // if (!response['isError']) {
      //    this.materialNumberList = response;
      //    this.showspinnner_GetMaterial = true;        
      // } 
      return response;
    });
  }


  getMaterial1() { 
    // this.spinner.show();
    this._fopHttpService.GetMaterils(this.fopId).subscribe((response: any) => {
      if (!response['isError']) {
         this.materialNumberList = response;
         this.showspinnner_GetMaterial = true;        
      } 
      //return response;
    });
  }

  getValueOfSelectedMaterial(index: number) {
    if (index >= 0) {
      let temparray: any = this.customerDetails.get(
        'materialDetails'
      ) as UntypedFormArray;
      let matNum = temparray?.value[index].materialNumber;

      if (matNum) {
        let val: any = this.materialNumberList.find(
          (k: any) => k.materialNumber == matNum
        );
        if (val?.materialName) {
          return val.materialName;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getCustomerInfobySoldToId() {
   
    this.spinner.show();
    
    // setTimeout(() => {
    this._getCustomerInfobySoldToId =  this._fopHttpService
      .getCustomerInfobySoldToId(this.fopId)
      .subscribe((response: any) => {
        if (response.response && response.response.length > 0) {
          const resp = response.response[0];
          this.fopBySoldToInfo = resp;

          // title for dropdown
          this.fopBySoldToInfo.soldTo_Address.forEach((add: any) => {
            add.title = `${add.soldTo_ID} : ${add.company} : ${add.addressType}`;
          });
          this.fopBySoldToInfo.shipTo_Address.forEach((add: any) => {
            add.title = `${add.shipTo_ID} : ${add.company} : ${add.addressType}`;
          });
          this.fopBySoldToInfo.billTo_Address.forEach((add: any) => {
            add.title = `${add.billTo_ID} : ${add.company} : ${add.addressType}`;
          });
          this.fopBySoldToInfo.payer_Address.forEach((add: any) => {
            add.title = `${add.payer_ID} : ${add.company} : ${add.addressType}`;
          });
          // end: titles for dropdown

          this.customerDetails.patchValue({
            soldTo: resp.soldTo_ID,
          });
          
          this.addressDropdownChange(resp.soldTo_ID, 'soldTo');
          this.showspinnner_FormData = true;
          setTimeout(() => {
            this.spinner.hide();
            }, 12000);
        }
      });

    //   this.spinner.hide();
    // }, 10000);  

  }

  addressDropdownChange(event: any, type: string) {
    switch (type) {
      case 'soldTo': {
        this.addressDropdownChangeSoldTo(event);
        break;
      }
      case 'shipTo': {
        this.addressDropdownChangeShipTo(event);
        break;
      }
      case 'billTo': {
        this.addressDropdownChangeBillTo(event);
        break;
      }
      case 'payer': {
        this.addressDropdownChangePayer(event);
        break;
      }

      default:
        break;
    }
  }
  addressDropdownChangePayer(eventData: any) {
    const selectedAddress = this.fopBySoldToInfo.payer_Address.filter(
      (add: any) => add['payer_ID'] == eventData
    )[0];

    if (selectedAddress) {
      const payerAdd = {
        companyNamePayerAddress: selectedAddress['company']
          ? selectedAddress['company']
          : '',
        addressLine1PayerAddress: selectedAddress['address']
          ? selectedAddress['address']
          : '',
        addressLine2PayerAddress: selectedAddress['address']
          ? selectedAddress['address']
          : '',
        cityPayerAddress: selectedAddress['city']
          ? selectedAddress['city']
          : '',
        statePayerAddress: selectedAddress['state']
          ? selectedAddress['state']
          : '',
        zipPayerAddress: selectedAddress['zip'] ? selectedAddress['zip'] : '',
        countryPayerAddress: selectedAddress['fopCountry']
          ? selectedAddress['fopCountry']
          : '',
      };

      this.customerDetails.patchValue(payerAdd);
    }
  }
  addressDropdownChangeBillTo(eventData: any) {
    const selectedAddress = this.fopBySoldToInfo.billTo_Address.filter(
      (add: any) => add['billTo_ID'] == eventData
    )[0];

    if (selectedAddress) {
      const billToAdd = {
        companyNameBillTo: selectedAddress['company']
          ? selectedAddress['company']
          : '',
        addressLine1BillTo: selectedAddress['address']
          ? selectedAddress['address']
          : '',
        addressLine2BillTo: selectedAddress['address']
          ? selectedAddress['address']
          : '',
        cityBillTo: selectedAddress['city'] ? selectedAddress['city'] : '',
        stateBillTo: selectedAddress['state'] ? selectedAddress['state'] : '',
        zipBillTo: selectedAddress['zip'] ? selectedAddress['zip'] : '',
        countryBillTo: selectedAddress['fopCountry']
          ? selectedAddress['fopCountry']
          : '',
        poboxBillTo: selectedAddress['poBox'],
      };
      console.log(billToAdd);

      this.customerDetails.patchValue(billToAdd);
    }
  }
  addressDropdownChangeShipTo(eventData: any) {
    const selectedAddress = this.fopBySoldToInfo.shipTo_Address.filter(
      (add: any) => add['shipTo_ID'] == eventData
    )[0];

    if (selectedAddress) {
      const shipToAdd = {
        companyNameShipToAddress: selectedAddress['company']
          ? selectedAddress['company']
          : '',
        addressLine1ShipToAddress: selectedAddress['address']
          ? selectedAddress['address']
          : '',
        addressLine2ShipToAddress: selectedAddress['address']
          ? selectedAddress['address']
          : '',
        cityShipToAddress: selectedAddress['city']
          ? selectedAddress['city']
          : '',
        stateShipToAddress: selectedAddress['state']
          ? selectedAddress['state']
          : '',
        zipShipToAddress: selectedAddress['zip'] ? selectedAddress['zip'] : '',
        countryShipToAddress: selectedAddress['fopCountry']
          ? selectedAddress['fopCountry']
          : '',
      };

      this.shipToAddress.patchValue([this.fb.group(shipToAdd)]);
    }
  }
  addressDropdownChangeSoldTo(eventData: any) {
    const selectedAddress = this.fopBySoldToInfo.soldTo_Address.filter(
      (add: any) => add['soldTo_ID'] == eventData
    )[0];
    if (selectedAddress) {
      const soldToAdd = {
        companyNameSoldTo:this.resolveObjectValue(this.customerDetails.value.companyNameSoldTo,selectedAddress['company']),
         addressLine1SoldTo:this.resolveObjectValue(this.customerDetails.value.addressLine1SoldTo,selectedAddress['address']),
         addressLine2SoldTo:this.resolveObjectValue(this.customerDetails.value.addressLine2SoldTo,selectedAddress['address']),
         citySoldTo:this.resolveObjectValue(this.customerDetails.value.citySoldTo,selectedAddress['city']),
         stateSoldTo:this.resolveObjectValue(this.customerDetails.value.stateSoldTo,selectedAddress['state']),
         zipSoldTo:this.resolveObjectValue(this.customerDetails.value.zipSoldTo,selectedAddress['zip'])
        
      };

      this.customerDetails.patchValue(soldToAdd);
    }
  }
resolveObjectValue(value1:any,value2:any){
  if(value1==''){
    return value2??'';
  }
  else{
    return value1;
  }
}
  validateEmailmultiple(emailsSeparator: any) {
    let re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return re.test(emailsSeparator);
  }

  validateEmailMultipleID(getPaperworkField: any) {
    if (getPaperworkField == this.customerDetails.value.invoicesEmail) {
      let emailsSeparator = this.customerDetails.value.invoicesEmail.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);
      this.InvalidEmailsList = '' + invalidEmails;
    }
    if (getPaperworkField == this.customerDetails.value.safetyDataSheetEmail) {
      let emailsSeparator =
        this.customerDetails.value.safetyDataSheetEmail.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);

      this.InvalidEmailsListafetyData = '' + invalidEmails;
    }
    if (getPaperworkField == this.customerDetails.value.cofAEmail) {
      let emailsSeparator = this.customerDetails.value.cofAEmail.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);

      this.InvalidEmailsListcofA = '' + invalidEmails;
    }
    if (
      getPaperworkField == this.customerDetails.value.orderAcknowledgementEmail
    ) {
      let emailsSeparator =
        this.customerDetails.value.orderAcknowledgementEmail.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);

      this.InvalidEmailsListorderAcknowledgement = '' + invalidEmails;
    }
    if (
      getPaperworkField ==
      this.customerDetails.value.advanceShippingNotificationEmail
    ) {
      let emailsSeparator =
        this.customerDetails.value.advanceShippingNotificationEmail.split(';');
      let invalidEmails: any = [];
      this.returnEmail(emailsSeparator, invalidEmails);

      this.InvalidEmailsListadvanceShippingNotification = '' + invalidEmails;
    }
  }

  returnEmail(emailsSeparator: any, invalidEmails: any) {
    for (const iterator of emailsSeparator) {
      if (!this.validateEmailmultiple(iterator.trim())) {
        invalidEmails.push(iterator.trim());
      }
    }
  }

  getMasterDataForDropdown() {
    this._fopHttpService.getMasterData().subscribe((response: any) => {
      // if (!response['isError']) {
      //   for (const dataObj of response.response) {
      //     this.setMasterDataObjects(dataObj);
      //   }       
      // }
      return response;
    });
  }

  getMasterAllDivisions(res:any) {
     this.BusinessUnits = res;
  }

  setMasterDataObjects(element: any) {
    if (element['masterKey'] == 'PAYMENT TERMS')
      this.PaymentTerms = element['masterkeyDataList'];
    if (element['masterKey'] == 'LANGUAGE')
      this.Languages = element['masterkeyDataList'];
    if (element['masterKey'] == 'INCOTERMS')
      this.IncoTerms = element['masterkeyDataList'];
    if (element['masterKey'] == 'SHIPPING CONDITIONS')
      this.ShippingConditions = element['masterkeyDataList'];
    if (element['masterKey'] == 'CURRENCY OF SALES')
      this.CurrencyOfSales = element['masterkeyDataList'];
  }

  get f() {
    return this.customerDetails.controls;
  }

  get shipToAddress(): UntypedFormArray {
    return this.customerDetails.get('shipToAddress') as UntypedFormArray;
  }
  shipToAddressValueControl(index: number, control: string): any {
    return this.customerDetails.get(['shipToAddress', index, control]);
  }

  getShipToAddessValidity(index: number) {
    return (this.customerDetails.get('shipToAddress') as UntypedFormArray).controls[
      index
    ];
  }

  next() {
    this.activeIndex('next');
  }

  mapMaterialListiWithFormGroup(newCustomerObj: any): any {
    let val = newCustomerObj.materialDetails as any[];
    let newval: any[] = [];
    let matdet =
      this.savedFormData != null
        ? this.savedFormData.materialDetails
        : ([] as any[]);
    for (let i = 0; i < val.length; i++) {
      let mat_value =
        matdet != undefined && matdet.length > 0 ? matdet[i] : undefined;
      if (mat_value) {
        newval.push({
          id: mat_value.id,
          materialNumber: val[i].materialNumber,
          materialName: val[i].materialName,
        });
      } else
        newval.push({
          id: 0,
          materialNumber: val[i].materialNumber,
          materialName: val[i].materialName,
        });
    }
    return newval;
  }

  eSolutionListiWithFormGroup(newCustomerObj: any): any {
    let val = newCustomerObj.eSolutionDetails as any[];
    let newval: any[] = [];
    let matdet =
      this.savedFormData != null
        ? this.savedFormData.eSolutionDetails
        : ([] as any[]);
    for (let i = 0; i < val.length; i++) {
      let mat_value =
        matdet != undefined && matdet.length > 0 ? matdet[i] : undefined;
      if (mat_value) {
        newval.push({
          id: mat_value.id,
          fullName: val[i].fullName,
          email: val[i].email,
        });
      } else
        newval.push({
          id: 0,
          fullName: val[i].fullName,
          email: val[i].email,
        });
    }
    return newval;
  }

  eSolutionEDIListiWithFormGroup(newCustomerObj: any): any {
    let val = newCustomerObj.eSolutionDetailsEDI as any[];
    let newval: any[] = [];
    let matdet =
      this.savedFormData != null
        ? this.savedFormData.eSolutionDetailsEDI
        : ([] as any[]);
    for (let i = 0; i < val.length; i++) {
      let mat_value =
        matdet != undefined && matdet.length > 0 ? matdet[i] : undefined;
      if (mat_value) {
        newval.push({
          id: mat_value.id,
          fullName: val[i].fullName,
          email: val[i].email,
          isedi:true,
        });
      } else
        newval.push({
          id: 0,
          fullName: val[i].fullName,
          email: val[i].email,
          isedi:true,
        });
    }
    return newval;
  }
  onSubmit() {
    if (this.fopReadyToGO === 'yes') {
      return;
    }
    this.submitted = true;
    //this.spinner.show();
    let dc = this.customerDetails.get('cityShipToAddress')?.value;
    let ds = this.customerDetails.get('stateShipToAddress')?.value;
    let cn = this.customerDetails.get('companyNameShipToAddress')?.value;
    let obj = {
      ShipDestinationCity: dc,
      ShipDestinationState: ds,
      ShipCompanyName: cn,
    };
    this._sharedParameter.setParameter(obj);
    let newCustomerObj = this.customerDetails.value;
    newCustomerObj.materialDetails =
      this.mapMaterialListiWithFormGroup(newCustomerObj);

    newCustomerObj.eSolutionDetails = 
      this.eSolutionListiWithFormGroup(newCustomerObj);

    newCustomerObj.eSolutionDetailsEDI =
      this.eSolutionEDIListiWithFormGroup(newCustomerObj);

    console.log(newCustomerObj);


    if (this.taxCountryFlag == 'CA') {
      this.customerDetails.get('taxExemptStatus')?.clearValidators();
      this.customerDetails.get('taxExemptStatus')?.updateValueAndValidity();
    }

    newCustomerObj.isReqFiledsFilled = !this.customerDetails.invalid;
    if (
      (this.customerDetails.value.isCofA === '1' &&
        this.customerDetails.value.cofAEmail.length < 1) ||
      (this.customerDetails.value.isAdvanceShippingNotification === '1' &&
        this.customerDetails.value.advanceShippingNotificationEmail.length <
          1) ||
      (this.customerDetails.value.isSafetyDataSheet === '1' &&
        this.customerDetails.value.safetyDataSheetEmail.length < 1) ||
      (this.customerDetails.value.isOrderAcknowledgement === '1' &&
        this.customerDetails.value.orderAcknowledgementEmail.length < 1)
    ) {
      newCustomerObj.isReqFiledsFilled = false;
    }
    this._fopHttpService
      .saveNewCustomer(newCustomerObj, this.taxCountryFlag)
      .subscribe({        
        next: (response: any) => {
          if (!response['isError']) {
            this.getSavedNewCustomerSetupData(response);
          }
          this._fopHttpService.displayToastr(response);
        },
        error: (err: any) => { 
        },
      });
  }

  test(value: any) {
    console.log(value);
  }
  closeForm() {
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    sessionStorage.removeItem('enduserFop');
    this.closeFormEvent.emit();
  }

  activeIndex(value: any) {
    this.setActiveItem.emit(value);
  }

  InitializeMaterialFormArray() {
    let frm = this.customerDetails.controls['materialDetails'] as UntypedFormArray;
    let matdet = this.savedFormData.materialDetails as any[];
    if (matdet.length > 0) {
      frm.clear();
      for (const iterator of matdet) {
        let ctrl = this.fb.group({
          materialNumber: [iterator.materialNumber],
          materialName: [iterator.materialName],
        });
        frm.push(ctrl);
      }
    }
  }

  InitializeEsolutionsFormArray() {
    let frm = this.customerDetails.controls['eSolutionDetails'] as UntypedFormArray;
    let esoldet = this.savedFormData.eSolutionDetails as any[];
    if (esoldet.length > 0) {
      frm.clear();
      for (const iterator of esoldet) {
        if(iterator.isedi != true)
        {
        let ctrl = this.fb.group({          
          fullName: [iterator.fullName],
          email: [iterator.email],
        });
        frm.push(ctrl);
      }
      }
    }


    let frmedi = this.customerDetails.controls['eSolutionDetailsEDI'] as UntypedFormArray;
    let esoldetedi = this.savedFormData.eSolutionDetails as any[];
    let chkedi = false;
    if (esoldetedi.length > 0) {
      frmedi.clear();
      for (const iterator of esoldetedi) {
        if(iterator.isedi == true)
          {
        let ctrl = this.fb.group({
          fullName: [iterator.fullName],
          email: [iterator.email],
        });
        frmedi.push(ctrl);
        chkedi = true;
      }
    }
    
    if(chkedi == false)
    {
      let ctrl = this.fb.group({
        fullName: '',
        email:'',
      });
      frmedi.push(ctrl);
    }
  }
}


  getSavedNewCustomerSetupData(response:any) {
    // this._fopHttpService
    //   .getNewCustomerSetupData(this.fopId)
    //   .subscribe((response: any) => {
        if (response.length == 0) {
          let tempCountry: any = sessionStorage.getItem('fopCountry');
          this.taxCountryFlag = tempCountry;
          this.savedFormData = { isCreditManual: 'False' };
          this.getFOPInitiationInfo();       
          return;
        }

        this.savedFormData = response[0];

        this.shipToAddress.clear();

        if (this.savedFormData) {
          
          if(this.endUserBusiness =='AP' ){
            
            if(this.savedFormData.isOrderAcknowledgement ==''|| this.savedFormData.isOrderAcknowledgement =='0')
            this.savedFormData.isOrderAcknowledgement='1'
            if(this.savedFormData.isAdvanceShippingNotification ==''|| this.savedFormData.isAdvanceShippingNotification =='0')
            this.savedFormData.isAdvanceShippingNotification='1'
           
           }
          
          this.showDownload = true;
          this.customerDetails.patchValue(this.savedFormData);
          this.savedFormData.shipToAddress?.forEach((shipAdd: any) => {
            this.shipToAddress.push(this.newShipToAddress(shipAdd));
          });

          this.InitializeMaterialFormArray();

          if(this.endUserBusiness !='AP' ){
          this.InitializeEsolutionsFormArray();
          }

          this.fopCountry = response[0].country == 'US' ? 'US' : 'CA';
          this.taxCountryFlag = this.fopCountry;
          this.submitted = true;
          sessionStorage.setItem('incoterm', this.savedFormData.incoTerms);
          sessionStorage.setItem('coa', this.savedFormData.isCofA);
          sessionStorage.setItem('coaEmail', this.savedFormData.cofAEmail);
          sessionStorage.setItem(
            'asn',
            this.savedFormData.isAdvanceShippingNotification
          );
          sessionStorage.setItem(
            'asnEmail',
            this.savedFormData.advanceShippingNotificationEmail
          );
        }
        this.showspinnner_SavedNew = true;
      // });
    
  }

  taxStatusChange(value: any) {
    if (value == '2') {
      this.customerDetails.patchValue({
        taxExemptCertificate: '',
        taxExemptCertificateName: '',
      });
    }
  }

  getTaxTemplate() {
    this._fopHttpService
      .getTaxExemptionTemplateCertificate()
      .subscribe((response: any) => {
        const data = response.taxCertificateDocument;
        const filename = response.taxCertificateName;
        this.download(data, filename);
      });
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      try {
        const file = event.target.files[0];
        let fileType = event.target.files[0].type;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let fileData: any = reader.result;
          fileData = fileData.replace(`data:${fileType};base64,`, '');
          this.customerDetails.patchValue({
            taxExemptCertificate: fileData,
            taxExemptCertificateName: event.target.files[0].name,
          });
        };
      } catch (err) {
        console.log(err);
      }
    }
  }

  getFOPInitiationInfo() {
    
    this._fopHttpService
      .GetFOPInitiationInfo(this.fopId)
      .subscribe((response: any) => {
        this.fopInitiationForm = response.response;
        if (this.fopInitiationForm) {
          this.customerDetails.patchValue({
            requestedBy: this.fopInitiationForm.createdBy,
            salesOrganization: this.fopInitiationForm.salesOrg,
            requestDate: this.fopInitiationForm.createdDate,
            distributionChannel: this.fopInitiationForm.distributionChannel,
          });
          if (this.fopInitiationForm.salesOrg.toUpperCase().includes('US')) {
            this.fopCountry = 'US';
          }          
        }
      });
  }

  download(data: any, fileName: string) {
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement('a');
    const filename = fileName;
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }

  isBillToAddressSameAsSoldTo() {
    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['companyNameSoldTo'].valueChanges.pipe(
              startWith(this.f['companyNameSoldTo'].value),
              tap((value) => this.f['companyNameBillTo'].setValue(value))
            );
          } else {
            this.f['companyNameBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['addressLine1SoldTo'].valueChanges.pipe(
              startWith(this.f['addressLine1SoldTo'].value),
              tap((value) => this.f['addressLine1BillTo'].setValue(value))
            );
          } else {
            this.f['addressLine1BillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['addressLine2SoldTo'].valueChanges.pipe(
              startWith(this.f['addressLine2SoldTo'].value),
              tap((value) => this.f['addressLine2BillTo'].setValue(value))
            );
          } else {
            this.f['addressLine2BillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['citySoldTo'].valueChanges.pipe(
              startWith(this.f['citySoldTo'].value),
              tap((value) => this.f['cityBillTo'].setValue(value))
            );
          } else {
            this.f['cityBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['stateSoldTo'].valueChanges.pipe(
              startWith(this.f['stateSoldTo'].value),
              tap((value) => this.f['stateBillTo'].setValue(value))
            );
          } else {
            this.f['stateBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            if (this.f['stateBillTo'].value.toUpperCase() == "PA" || this.f['stateBillTo'].value.toUpperCase() == "PENNSYLVANIA") {
              this.disableTankRegBillTo = false;
              document.getElementById("dvtankregBillTo")?.removeAttribute("hidden");
            }
            return this.f['tankregSoldTo'].valueChanges.pipe(
              startWith(this.f['tankregSoldTo'].value),
              tap((value) => this.f['tankregBillTo'].setValue(value))
            );
          } else {
            document.getElementById("dvtankregBillTo")?.setAttribute("hidden", "hidden");
            this.f['tankregBillTo'].reset();
            this.disableTankRegBillTo = true;
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['zipSoldTo'].valueChanges.pipe(
              startWith(this.f['zipSoldTo'].value),
              tap((value) => this.f['zipBillTo'].setValue(value))
            );
          } else {
            this.f['zipBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['countrySoldTo'].valueChanges.pipe(
              startWith(this.f['countrySoldTo'].value),
              tap((value) => this.f['countryBillTo'].setValue(value))
            );
          } else {
            this.f['countryBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['contactNameSoldTo'].valueChanges.pipe(
              startWith(this.f['contactNameSoldTo'].value),
              tap((value) => this.f['contactNameBillTo'].setValue(value))
            );
          } else {
            this.f['contactNameBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['phoneSoldTo'].valueChanges.pipe(
              startWith(this.f['phoneSoldTo'].value),
              tap((value) => this.f['phoneBillTo'].setValue(value))
            );
          } else {
            this.f['phoneBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['faxSoldTo'].valueChanges.pipe(
              startWith(this.f['faxSoldTo'].value),
              tap((value) => this.f['faxBillTo'].setValue(value))
            );
          } else {
            this.f['faxBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isBillToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['emailSoldTo'].valueChanges.pipe(
              startWith(this.f['emailSoldTo'].value),
              tap((value) => this.f['emailBillTo'].setValue(value))
            );
          } else {
            this.f['emailBillTo'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  isShipToAddressSameAsSoldTo() {
    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['companyNameSoldTo'].valueChanges.pipe(
              startWith(this.f['companyNameSoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([
                  { companyNameShipToAddress: value },
                ])
              )
            );
          } else {
            this.f['companyNameShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['addressLine1SoldTo'].valueChanges.pipe(
              startWith(this.f['addressLine1SoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([
                  { addressLine1ShipToAddress: value },
                ])
              )
            );
          } else {
            this.f['addressLine1ShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['addressLine2SoldTo'].valueChanges.pipe(
              startWith(this.f['addressLine2SoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([
                  { addressLine2ShipToAddress: value },
                ])
              )
            );
          } else {
            this.f['addressLine2ShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['citySoldTo'].valueChanges.pipe(
              startWith(this.f['citySoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([{ cityShipToAddress: value }])
              )
            );
          } else {
            this.f['cityShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['stateSoldTo'].valueChanges.pipe(
              startWith(this.f['stateSoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([{ stateShipToAddress: value }])
              )
            );
          } else {
            this.f['stateShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {

            if (this.customerDetails.get(['shipToAddress', 0, 'stateShipToAddress'])?.value.toUpperCase() == "PA" || this.customerDetails.get(['shipToAddress', 0, 'stateShipToAddress'])?.value.toUpperCase() == "PENNSYLVANIA") {
              this.disableTankRegShipToAddress = false;
              document.getElementById("dvtankregShipToAddress")?.removeAttribute("hidden");
            }

            return this.f['tankregSoldTo'].valueChanges.pipe(
              startWith(this.f['tankregSoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([{ tankregShipToAddress: value }])
              )
            );
          } else {
            document.getElementById("dvtankregShipToAddress")?.setAttribute("hidden", "hidden");
            this.f['tankregShipToAddress'].reset();
            this.disableTankRegShipToAddress = true;
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['zipSoldTo'].valueChanges.pipe(
              startWith(this.f['zipSoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([{ zipShipToAddress: value }])
              )
            );
          } else {
            this.f['zipShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['countrySoldTo'].valueChanges.pipe(
              startWith(this.f['countrySoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([{ countryShipToAddress: value }])
              )
            );
          } else {
            this.f['countryShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['contactNameSoldTo'].valueChanges.pipe(
              startWith(this.f['contactNameSoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([
                  { contactNameShipToAddress: value },
                ])
              )
            );
          } else {
            this.f['contactNameShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['phoneSoldTo'].valueChanges.pipe(
              startWith(this.f['phoneSoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([{ phoneShipToAddress: value }])
              )
            );
          } else {
            this.f['phoneShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['faxSoldTo'].valueChanges.pipe(
              startWith(this.f['faxSoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([{ faxShipToAddress: value }])
              )
            );
          } else {
            this.f['faxShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isShipToAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['emailSoldTo'].valueChanges.pipe(
              startWith(this.f['emailSoldTo'].value),
              tap((value) =>
                this.shipToAddress.patchValue([{ emailShipToAddress: value }])
              )
            );
          } else {
            this.f['emailShipToAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  isPayerAddressSameAsSoldTo() {
    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['companyNameSoldTo'].valueChanges.pipe(
              startWith(this.f['companyNameSoldTo'].value),
              tap((value) => this.f['companyNamePayerAddress'].setValue(value))
            );
          } else {
            this.f['companyNamePayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['addressLine1SoldTo'].valueChanges.pipe(
              startWith(this.f['addressLine1SoldTo'].value),
              tap((value) => this.f['addressLine1PayerAddress'].setValue(value))
            );
          } else {
            this.f['addressLine1PayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['addressLine2SoldTo'].valueChanges.pipe(
              startWith(this.f['addressLine2SoldTo'].value),
              tap((value) => this.f['addressLine2PayerAddress'].setValue(value))
            );
          } else {
            this.f['addressLine2PayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['citySoldTo'].valueChanges.pipe(
              startWith(this.f['citySoldTo'].value),
              tap((value) => this.f['cityPayerAddress'].setValue(value))
            );
          } else {
            this.f['cityPayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['stateSoldTo'].valueChanges.pipe(
              startWith(this.f['stateSoldTo'].value),
              tap((value) => this.f['statePayerAddress'].setValue(value))
            );
          } else {
            this.f['statePayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            if (this.f['statePayerAddress'].value.toUpperCase() == "PA" || this.f['statePayerAddress'].value.toUpperCase() == "PENNSYLVANIA") {
              this.disableTankRegPayerAddress = false;
              document.getElementById("dvtankregPayerAddress")?.removeAttribute("hidden");
            }
            return this.f['tankregSoldTo'].valueChanges.pipe(
              startWith(this.f['tankregSoldTo'].value),
              tap((value) => this.f['tankregPayerAddress'].setValue(value))
            );
          } else {
            document.getElementById("dvtankregPayerAddress")?.setAttribute("hidden", "hidden");
            this.f['tankregPayerAddress'].reset();            
            this.disableTankRegPayerAddress = true;
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['zipSoldTo'].valueChanges.pipe(
              startWith(this.f['zipSoldTo'].value),
              tap((value) => this.f['zipPayerAddress'].setValue(value))
            );
          } else {
            this.f['zipPayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['countrySoldTo'].valueChanges.pipe(
              startWith(this.f['countrySoldTo'].value),
              tap((value) => this.f['countryPayerAddress'].setValue(value))
            );
          } else {
            this.f['countryPayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['contactNameSoldTo'].valueChanges.pipe(
              startWith(this.f['contactNameSoldTo'].value),
              tap((value) => this.f['contactNamePayerAddress'].setValue(value))
            );
          } else {
            this.f['contactNamePayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['phoneSoldTo'].valueChanges.pipe(
              startWith(this.f['phoneSoldTo'].value),
              tap((value) => this.f['phonePayerAddress'].setValue(value))
            );
          } else {
            this.f['phonePayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['faxSoldTo'].valueChanges.pipe(
              startWith(this.f['faxSoldTo'].value),
              tap((value) => this.f['faxPayerAddress'].setValue(value))
            );
          } else {
            this.f['faxPayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();

    this.isPayerAddressSame.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            return this.f['emailSoldTo'].valueChanges.pipe(
              startWith(this.f['emailSoldTo'].value),
              tap((value) => this.f['emailPayerAddress'].setValue(value))
            );
          } else {
            this.f['emailPayerAddress'].reset();
            return EMPTY;
          }
        })
      )
      .subscribe();
  }
  getTemplate(certificateType: any) {
    this._fopHttpService
      .getCATaxInfoCertificateTemplates(certificateType)
      .subscribe((response: any) => {
        const data = response.taxCertificateDocument;
        const filename = response.taxCertificateName;
        this.download(data, filename);
      });
  }
  onUploadDocChange(event: any, inputName: string) {
    if (event.target.files.length > 0) {
      try {
        const file = event.target.files[0];
        let fileType = event.target.files[0].type;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let fileData: any = reader.result;
          fileData = fileData.replace(`data:${fileType};base64,`, '');
          this.uploadDocChangeSwitchInput(event, inputName, fileData);
        };
      } catch (err) {
        console.log(err);
      }
    }
  }
  uploadDocChangeSwitchInput(event: any, inputName: string, fileData: any) {
    switch (inputName) {
      case 'GstCertificate':
        this.customerDetails.patchValue({
          taxInformation: {
            gstCertificateDocument: fileData,
            gstHstCertificateName: event.target.files[0].name,
          },
        });

        break;
      case 'QstCertificate':
        this.customerDetails.patchValue({
          taxInformation: {
            qstCertificateDocument: fileData,
            qstCertificateName: event.target.files[0].name,
          },
        });

        break;
      case 'SalesTaxCertificate':
        this.customerDetails.patchValue({
          taxInformation: {
            salesTaxCertificateDocument: fileData,
            salesTaxCertificateName: event.target.files[0].name,
          },
        });
        break;
      default:
        break;
    }
  }

  get materialDetails() {
    return this.customerDetails.controls['materialDetails'] as UntypedFormArray;
  }
  addMaterial(): void {
    const materialForm = this.fb.group({
      materialNumber: [''],
      materialName: [''],
    });

    this.materialDetails.push(materialForm);
  }

  removeMaterial(index: number): void {
    this.materialDetails.removeAt(index);
    let itm = this.savedFormData.materialDetails as any[];
    itm.splice(index, 1);
    this.savedFormData.materialDetails = itm;
  }

  get eSolutionDetails() {
    return this.customerDetails.controls['eSolutionDetails'] as UntypedFormArray;
  }
  addEsolution(): void {
    const eSolutionForm = this.fb.group({
      fullName: [''],
      email: ['',[Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      isedi: true 
    });

    this.eSolutionDetails.push(eSolutionForm);
  }

  removeEsolution(index: number): void {
    this.eSolutionDetails.removeAt(index);
  }

  get eSolutionDetailsEDI() {
    return this.customerDetails.controls['eSolutionDetailsEDI'] as UntypedFormArray;
  }
  
  addEsolutionEDI(): void {
    const eSolutionFormEDI = this.fb.group({
      fullName: [''],
      email: ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      isedi: true 
    });

    this.eSolutionDetailsEDI.push(eSolutionFormEDI);
  }

  removeEsolutionEDI(index: number): void {
    this.eSolutionDetailsEDI.removeAt(index);

  }

  public openPdf(filePath: string): void {
    window.open(filePath, '_blank');
  }

  onStatechanged(value: any, value1: any) {
    if (
      value.target.value.toLowerCase() == 'PA'.toLowerCase() ||
      value.target.value.toLowerCase() == 'PENNSYLVANIA'.toLowerCase() ||
      value.target.value.toLowerCase() == 'PA/PENNSYLVANIA'.toLowerCase()
    ) {
      if (value1 == 1) {
        this.disableTankRegBillTo = false;
      }
      else if (value1 == 2) {
        this.disableTankRegSoldTo = false;

        if (this.f['stateBillTo'].value.toLowerCase() == 'PA'.toLowerCase() || this.f['stateBillTo'].value.toLowerCase() == 'PENNSYLVANIA'.toLowerCase() || this.f['stateBillTo'].value.toLowerCase() == 'PA/PENNSYLVANIA'.toLowerCase()) {
          this.disableTankRegBillTo = false;
        }

        if (this.f['statePayerAddress'].value.toLowerCase() == 'PA'.toLowerCase() || this.f['statePayerAddress'].value.toLowerCase() == 'PENNSYLVANIA'.toLowerCase() || this.f['statePayerAddress'].value.toLowerCase() == 'PA/PENNSYLVANIA'.toLowerCase()) {
          this.disableTankRegPayerAddress = false;
        }
      }
      else if (value1 == 3) {
        this.disableTankRegSoldTo = false;
        if (this.f['statePayerAddress'].value.toLowerCase() == 'PA'.toLowerCase() || this.f['statePayerAddress'].value.toLowerCase() == 'PENNSYLVANIA'.toLowerCase() || this.f['statePayerAddress'].value.toLowerCase() == 'PA/PENNSYLVANIA'.toLowerCase()) {
          this.disableTankRegPayerAddress = false;
        }
      }
      else if (value1 == 4) {
        this.disableTankRegShipToAddress = false;
      }
    }
    else {
      if (value1 == 1) {
        this.disableTankRegBillTo = true;
        this.f['tankregBillTo'].reset();
      }
      else if (value1 == 2) {
        this.disableTankRegSoldTo = true;
        this.f['tankregSoldTo'].reset();
      }
      else if (value1 == 3) {
        this.disableTankRegPayerAddress = true;
        this.f['tankregPayerAddress'].reset();

      }
      else if (value1 == 4) {
        this.disableTankRegShipToAddress = true;

      }
    }
  }
}
