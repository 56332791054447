import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { SharedParameterService } from 'src/app/services/sharedparameterservice.service';

@Component({
  selector: 'app-ship-to-change',
  templateUrl: './ship-to-change.component.html',
  styleUrls: ['./ship-to-change.component.css'],
})
export class ShipToChangeComponent implements OnInit {
  items!: MenuItem[];
  activeItem: any;
  disableForm: boolean = false;
  fopId: any = sessionStorage.getItem('fopId');
  shipReqFormData: any;
  selectedShipRequest: string = '';
  isShipTo = false;
  userId = parseInt(sessionStorage.getItem('userId') + '');
  isExternalUser: boolean = JSON.parse(
    sessionStorage.getItem('isExternalUser') + ''
  );
  isDocumentationTabShow:boolean=false;
  constructor(
    private _fopHttpService: FopHttpService,
    private sharedService: SharedParameterService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    const paramsFromRoute = this.router.snapshot?.paramMap;
    if (paramsFromRoute?.get('fopId')) {
      this.fopId = paramsFromRoute.get('fopId');
      sessionStorage.setItem('fopId', this.fopId);
    }
    sessionStorage.setItem('fopId', this.fopId);
  }

  enduserFop: any = '';
  ngOnInit(): void {
   //this.spinner.show();
    this._fopHttpService
      .getFOPGridData(this.userId, this.isExternalUser)
      .subscribe((response: any) => {
        let gridData = response.response?.filter(
          (item: any) => item.fopId == sessionStorage.getItem('fopId')
        )[0];
        if (gridData) {
          sessionStorage.setItem('enduserFop', JSON.stringify(gridData));
          this.enduserFop = sessionStorage.getItem('enduserFop') ?? '';
        }
        
      });
  
    this.isShipTo = true;
    this.items = [
      {
        label: this.translate.instant('NEW_SHIP_TO_LOCATION_SET_UP'),
        command: (STevent) => {
          this.activeItem = STevent.item;  
        },
      },
      {
        label: this.translate.instant('SHIPPING_REQUIREMENTS'),
        command: (STevent) => {
          this.activeItem = STevent.item;
        },
      },
      {
        label: this.translate.instant('HISTORY_AND_COMMENTS'),
        command: (STevent) => {
          this.activeItem = STevent.item;
        },
      },
    ];
    let currentfopST:any=sessionStorage.getItem('enduserFop')==null?sessionStorage.getItem('fop'):sessionStorage.getItem('enduserFop');
    
    if(currentfopST)
    {
      currentfopST=JSON.parse(currentfopST);      
      if(currentfopST.businessUnit=="AP"){
        this.isDocumentationTabShow=true;
        this.items.push({
          label: this.translate.instant('DOCUMENTATION'),
          command: (STevent) => {
            this.activeItem = STevent.item;
          },
        })
      }
    }
    this.activeItem = this.items[0];
    if (this.fopId) {
      this.getSavedShippingData();
    }
    
    // setTimeout(() => {
    //   this.spinner.hide();
    //   }, 12000); 
  }
  

  value: number = 0;
  setActiveItem(value: any) {
    this.value = this.items.indexOf(this.activeItem);
    if (value == 'next') this.value = this.value + 1;
    else this.value = this.value - 1;
    this.activeItem = this.items[this.value];
  }

  getFopIdFromSession() {
    let basfCCfop: any = sessionStorage.getItem('fop');
    if (basfCCfop) basfCCfop = JSON.parse(basfCCfop);
    let enduserFop: any = sessionStorage.getItem('enduserFop');
    if (enduserFop) enduserFop = JSON.parse(enduserFop);
    if (basfCCfop?.fopId && basfCCfop.type !== 'edit') {
      if (basfCCfop?.gridDisplay !== this.translate.instant('PENDING_WITH_CUSTOMER')) {
        this.disableForm = true;
      }
    }
    else if (enduserFop?.gridDisplay !== this.translate.instant('PENDING_WITH_CUSTOMER')){      
      this.disableForm = true;
    }
    this.fopId = sessionStorage.getItem('fopId');
    sessionStorage.setItem('fopId', this.fopId);
  }

  getSavedShippingData() { 
    //this.spinner.show();

    this._fopHttpService
      .getShippingReqData(this.fopId)
      .subscribe((result: any) => {
        const response = result.response;
        switch (response.shipingType) {
          
          case 'PKGTRUCK':
            this.selectedShipRequest = 'PTR';
            this.shipReqFormData = response.packageTruckRequest;
            break;
          case 'RAIL':
            this.selectedShipRequest = 'RR';
            this.shipReqFormData = response.railRequest;
            break;
          case 'BULKTRUCK':
            this.shipReqFormData = response.bulTrukRequest;
            this.selectedShipRequest = 'BTR';
            break;
          default:
            break;
        }

        // setTimeout(() => {
        // this.spinner.hide();
        // }, 10000); 

      });
  }

  ExportAll(){
    this.sharedService.ExportAll();
  }

  closeEvent() {
    this.fopId = null;
  }
}
