import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { FopHttpService } from 'src/app/services/fop-http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-credit-application',
  templateUrl: './credit-application.component.html',
  styleUrls: ['./credit-application.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CreditApplicationComponent implements OnInit, OnDestroy {
  @Input() disableForm: any;
  @Output() closeFormEvent = new EventEmitter();
  @Output() setActiveItem = new EventEmitter();
  @Output() taxTab = new EventEmitter();
  saveValue = '';
  creditUploadApplicationForm: UntypedFormGroup;
  creditApplicationForm: UntypedFormGroup;
  creditApplicationCanadaForm: UntypedFormGroup;
  submitted: boolean = false;
  fopId = sessionStorage.getItem('fopId');
  fopTypeId: any;

  showspinnner_GetFOP: boolean = false;
  showspinnner_GetCredit: boolean = false;
  showspinnner_GetCACredit: boolean = false;

  savedFormData: any;
  showDownload = false;
  country: any;
  creditType = true;
  usCreditApplicationTypes: any = [
    // {
    //   name: 'Select Type',
    //   value: 'NONE',
    // },
    {
      name: 'Manual',
      value: 'MANUAL',
    },
    {
      name: 'Upload',
      value: 'UPLOAD',
    },
  ];
  disableCreditFormType: boolean = false;
  creditFormType: string = 'NONE';
  disableUSformTypeSelection: boolean = false;
  fopReadyToGO = sessionStorage.getItem('fopReadyToGO');
  displayDialog: boolean = false;
  signImage: any;
  bankingInfoSigned: boolean = false;
  bankingInfoSign: any;
  eSignType: any;
  acknowledgmentSigned: boolean = false;
  acknowledgmentSign: any;

  constructor(
    private fb: UntypedFormBuilder,
    private _fopHttpService: FopHttpService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.creditUploadApplicationForm = this.fb.group({
      isReqFiledsFilled: [false],
      fopId: [parseInt(sessionStorage.getItem('fopId') + '')],
      creditUploadDoc: ['', Validators.required],
      creditUploadDocName: [''],
      contactName: ['', Validators.required],
      title: ['', Validators.required],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      createdBy: [sessionStorage.getItem('userId'), Validators.required],
      uploadW9: [''],
      uploadW9Name: [''],
      purchaseOrder: [''],
      purchaseOrderName: [''],
    });

    this.creditApplicationForm = this.fb.group({
      isReqFiledsFilled: [false],
      fopId: [sessionStorage.getItem('fopId')],
      createdBy: [sessionStorage.getItem('userId')],
      legalBusinessName: ['', Validators.required],
      otherBusinessName: [''],
      businessAddress1: ['', Validators.required],
      businessAddress2: [''],
      businessCity: ['', Validators.required],
      businessState: ['', Validators.required],
      businessZip: ['', Validators.required],
      businessPhone: [
        '',
        [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      businessFax: [''],
      businessEmail: ['', [Validators.required, Validators.email]],
      businessAge: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(1),
        ],
      ],
      website: [''],
      dunsNumber: ['', Validators.required],
      billingAddress1: ['', Validators.required],
      billingAddress2: [''],
      billingCity: ['', Validators.required],
      billingState: ['', Validators.required],
      billingZip: ['', Validators.required],
      financialNameAndTitle: [''],
      financialPhone: [
        '',
        [
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      financialEmail: ['', Validators.email],
      accountsPayableNameAndTitle: [''],
      accountsPayablePhone: [
        '',
        [
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      accountsPayableEmail: ['', Validators.email],
      emailForDeliveryOfInvoices: ['', Validators.email],
      emailForAccountsPayable: ['', Validators.email],
      taxpayerIdentificationNumber: [''],
      salesCertificate: [''],
      attachCertificate: [''],
      vat: [''],
      bankName: ['', Validators.required],
      bankBranch: [''],
      bankAddressLine1: [''],
      bankAddressLine2: [''],
      bankCity: [''],
      bankState: [''],
      bankZip: [''],
      bankAccountNumber: ['', Validators.required],
      typeOfAccount: ['', Validators.required],
      bankContact: [''],
      bankPhone: [
        '',
        [
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      bankEmail: ['', Validators.email],
      signature: ['', Validators.required],
      printedName: [''],
      bankTitle: [''],
      bankDate: [''],
      isDeliveryAddressDifferent: [''],
      deliveryAddressOther: [''],
      cityOther: [''],
      stateOther: [''],
      zipOther: [''],
      productsToBePurchased: [''],
      ccContact: [''],
      legalBusinessNameOther: [''],
      amountOfCreditRequested: [''],
      termsRequested: [''],
      firmName: ['', Validators.required],
      acknowledgeSignature: ['', Validators.required],
      aknowledgePrintedName: [''],
      aknowledegeTitle: [''],
      aknowledgeDate: [''],
      aknowledgePrintedName2: [''],
      aknowledegeTitle2: [''],
      aknowledgeDate2: [''],
      isTradeReferenceManual: [''],
      tradeReferenceFile: [''],
      trades: this.fb.array([]),
      signatureName: [''],
      tradeReferenceFileName: [''],
      acknowledgeSignatureName: [''],
      uploadW9: ['', Validators.required],
      uploadW9Name: [''],
      purchaseOrder: [''],
      purchaseOrderName: [''],
    });

    this.creditApplicationCanadaForm = this.fb.group({
      isReqFiledsFilled: [false],
      fopId: parseInt(sessionStorage.getItem('fopId') + ''),
      createdBy: [sessionStorage.getItem('userId')],
      applicant: this.fb.group({
        leagalBusinessName: ['', Validators.required],
        businessAddress: ['', Validators.required],
        businessCity: ['', Validators.required],
        businessProvince: ['', Validators.required],
        businessZip: ['', Validators.required],
        mailingAddress: ['', Validators.required],
        mailingCity: ['', Validators.required],
        mailingProvince: ['', Validators.required],
        mailingZip: ['', Validators.required],
        phone: [
          '',
          [
            Validators.required,
            Validators.pattern('[- +()0-9]+'),
            Validators.minLength(10),
            Validators.maxLength(17),
          ],
        ],
        fax: [''],
        emailAddress: ['', [Validators.required, Validators.email]],
        shippingToAddress: [''],
        webAddress: [''],
        duns: ['', Validators.required],
        estimatedAnullPurchase_BASF_CA: [''],
        finalncialContact: [''],
        finalncialPhone: [
          '',
          [
            Validators.pattern('[- +()0-9]+'),
            Validators.minLength(10),
            Validators.maxLength(17),
          ],
        ],
        accountPaybleContact: [''],
        accountPayblePhone: [
          '',
          [
            Validators.pattern('[- +()0-9]+'),
            Validators.minLength(10),
            Validators.maxLength(17),
          ],
        ],
        creditAmountRequested: [''],
        termsRequested: [''],
        businessDuration: [
          '',
          [
            Validators.required,
            Validators.pattern('^[0-9]*$'),
            Validators.minLength(1),
          ],
        ],
      }),
      businessInfo: this.fb.group({
        businessType: [''],
        ownerShip: this.fb.array([]),
        financialStatement: [''],
        financialStatementName: [''],
      }),
      caBankingInfo: this.fb.group({
        bankDetails: this.fb.array([]),
        caBankSignature: ['', Validators.required],
        caSignatrueName: [''],
        caBankPrintedName: [''],
        caBankTitle: [''],
        caBankDate: [''],
      }),
      taxInformation: this.fb.group({
        gstShipmentCertifcate: [''],
        gstCertificateDocument: [''],
        gstHstCertificateName: [''],
        qstShipmentCertifcate: [''],
        qstCertificateDocument: [''],
        qstCertificateName: [''],
        salesTaxCertifcate: [''],
        salesTaxCertificateDocument: [''],
        salesTaxCertificateName: [''],
        vat: [''],
        otherTaxIdentification: [''],
      }),
      tradeReference: this.fb.group({
        isCATradeReferenceManual: [''],
        caTradeReferenceFile: [''],
        tradeReferenceFileName: [''],
        trades: this.fb.array([]),
      }),
      acknowledge: this.fb.group({
        firmName: ['', Validators.required],
        caAcknowledgeSignature1: ['', Validators.required],
        caAcknowledgeSignature1Name: [''],
        caAknowledgePrintedName1: [''],
        caAknowledegeTitle1: [''],
        caAknowledgePrintedName2: [''],
        caAknowledegeTitle2: [''],
        aknowledgeDate2: [''],
        aknowledgeDate1: [''],
      }),
      documentsUpload: this.fb.group({
        purchaseOrder: [''],
        purchaseOrderName: [''],
      }),
    });
    this.translate.stream('ALL_FOP').subscribe(resp => {
      this.initializeTabItems();
    })
  }

  initializeTabItems() {
    this.usCreditApplicationTypes = [
      // {
      //   name: this.translate.instant('SELECT_TYPE'),
      //   value: 'NONE',
      // },
      {
        name: this.translate.instant('MANUAL'),
        value: 'MANUAL',
      },
      {
        name: this.translate.instant('UPLOAD'),
        value: 'UPLOAD',
      },
    ];
  }

  ngOnInit() {
    this.spinner.show();

    let endUserFop: any = sessionStorage.getItem('enduserFop');
    if (endUserFop) {
      endUserFop = JSON.parse(endUserFop);
      this.fopTypeId = endUserFop.fopTypeId;
    }

    this._fopHttpService
      .GetFOPInitiationInfo(this.fopId)
      .subscribe((response: any) => {
        this.country = response.response.country;
        this.showspinnner_GetFOP = true;
        if (this.country == 'US') this.getSavedCreditApplicationData();
        if (this.country == 'CA') this.getSavedCACreditApplicationData();
      });

      if (this.disableForm || this.fopReadyToGO === 'yes') {
        this.creditApplicationForm.disable();
        this.creditApplicationCanadaForm.disable();
        this.creditUploadApplicationForm.disable();
      }

    if(this.showspinnner_GetFOP == true && this.showspinnner_GetCredit == true && this.showspinnner_GetCACredit == true)
      {
        this.spinner.hide();
      }
      else{
   setTimeout(() => {
      this.spinner.hide();
    }, 9000);

      }

 

    this.initializeTabItems();
  }

  ngOnDestroy(): void {
    this.onSubmit();
  }

  get f() {
    if (this.country == 'US') return this.creditApplicationForm.controls;
    else return this.creditApplicationCanadaForm.controls;
  }

  get up() {
    return this.creditUploadApplicationForm.controls;
  }

  activeIndex(value: any) {
    this.setActiveItem.emit(value);
  }

  next() {
    this.activeIndex('next');
  }

  onSubmit() {
    if (
      this.creditFormType === 'NONE' ||
      this.fopReadyToGO === 'yes' ||
      this.saveValue == 'back'
    ) {
      return;
    }
    this.submitted = true;
    if (this.creditFormType === 'UPLOAD') {
      this.saveUploadForm();
    } else if (this.country == 'US' && this.creditFormType === 'MANUAL') {
      this.saveUS_CreditForm();
    } else if (this.country == 'CA' && this.creditFormType === 'MANUAL') {
      this.saveCA_CreditForm();
    }
  }

  saveUploadForm() {
    this.creditUploadApplicationForm.value.isReqFiledsFilled =
      !this.creditUploadApplicationForm.invalid;
    if (this.country == 'US') {
      if (this.creditUploadApplicationForm.value.uploadW9.length < 1) {
        this.creditUploadApplicationForm.value.isReqFiledsFilled = false;
      }
    }
    this.spinner.show();
    this._fopHttpService
      .saveCreditUploadSetup(this.creditUploadApplicationForm.value)
      .subscribe((response: any) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 5000);
        if (!response['isError']) {
          console.log('credit updaload form saved', response);
        }
        this._fopHttpService.displayToastr(response);
      });
  }

  saveUS_CreditForm() {
    this.creditApplicationForm.value.isReqFiledsFilled =
      !this.creditApplicationForm.invalid;
    this.spinner.show();
    let creditApplicationFormObj = this.creditApplicationForm.value;
    this._fopHttpService
      .saveCreditSetup(creditApplicationFormObj)
      .subscribe((response: any) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 9000);
        if (!response['isError']) {
          console.log('save us credit form:', response);
        }
        this._fopHttpService.displayToastr(response);
      });
  }

  saveCA_CreditForm() {
    this.creditApplicationCanadaForm.value.isReqFiledsFilled =
      !this.creditApplicationCanadaForm.invalid;
    this.spinner.show();
    let creditApplicationCanadaFormObj = this.creditApplicationCanadaForm.value;
    this._fopHttpService
      .saveCreditCanadaSetup(creditApplicationCanadaFormObj)
      .subscribe((response: any) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 5000);
        if (!response['isError']) {
          console.log('saveCreditCanadaSetup: ', response);
        }
        this._fopHttpService.displayToastr(response);
      });
  }

  closeForm() {
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    sessionStorage.removeItem('enduserFop');
    this.closeFormEvent.emit();
  }

  onUsFormTypeChange(event: any) {
    this.creditFormType = event.value;
    if (this.creditFormType == 'MANUAL') {
      let tempStore: any = sessionStorage.getItem('enduserFop');
      let tempCountry = JSON.parse(tempStore);
      if (tempCountry.country == 'CA') {
        this.taxTab.emit(true);
      } else {
        this.taxTab.emit(false);
      }
    }
  }

  getSavedCreditApplicationData() {
    this._fopHttpService
      .getCreditApplicationData(this.fopId)
      .subscribe((response: any) => {
        this.creditFormType = response.response.creditType;
        if (this.creditFormType) {
          this.disableUSformTypeSelection = true;

          if (this.creditFormType === 'MANUAL') {
            this.getSavedCreditApplicationDataManual(response.response);
          } else if (this.creditFormType === 'UPLOAD') {
            this.getSavedCreditApplicationDataUpload(response.response);
          }
          if (this.submitted) {
            this.disableCreditFormType = true;
          }
          this.showspinnner_GetCredit = true;
        }
      });
  }

  getSavedCreditApplicationDataManual(response: any) {
    this.savedFormData = response.creditDetails[0];
    if (!this.savedFormData) return;
    this.showDownload = true;
    response.creditDetails[0].trades?.forEach((trade: any) => {
      this.trades.push(this.fb.group(trade));
    });
    this.creditApplicationForm.patchValue(this.savedFormData);
    this.bankingInfoSign = this.savedFormData.signature
      ? `data:image/png;base64,${this.savedFormData.signature}`
      : null;
    this.bankingInfoSigned = this.bankingInfoSign !== null;
    this.acknowledgmentSign = this.savedFormData.acknowledgeSignature
      ? `data:image/png;base64,${this.savedFormData.acknowledgeSignature}`
      : null;
    this.acknowledgmentSigned = this.acknowledgmentSign !== null;
    this.submitted = true;
    let tempStore: any = sessionStorage.getItem('enduserFop');
    let tempCountry = JSON.parse(tempStore);
    if (tempCountry.country == 'CA') {
      this.taxTab.emit(true);
    } else {
      this.taxTab.emit(false);
    }
  }
  getSavedCreditApplicationDataUpload(response: any) {
    this.savedFormData = response.creditUploadDetails;
    if (!this.savedFormData) return;
    this.showDownload = true;
    this.creditUploadApplicationForm.patchValue(this.savedFormData);
    this.submitted = true;
  }

  getSavedCACreditApplicationData() {
    this._fopHttpService
      .getCACreditApplicationData(this.fopId)
      .subscribe((response: any) => {
        this.creditFormType = response.creditType;
        if (response.creditType == 'UPLOAD') {
          this.showDownload = true;
          this.creditUploadApplicationForm.patchValue(
            response.creditUploadDetails
          );
          this.creditType = true;
          this.showspinnner_GetCACredit = true;
        } else {
          this.getSavedCACreditApplicationDataManual(response);
        }
        if (this.showDownload) {
          this.disableCreditFormType = true;
        }
      });
  }
  getSavedCACreditApplicationDataManual(response: any) {
    this.creditType = false;
    this.savedFormData = response.creditDetails;
    if (this.savedFormData) {
      this.showDownload = true;
      this.savedFormData.businessInfo?.ownerShip.forEach((owner: any) => {
        this.ownerShip.push(this.fb.group(owner));
      });
      this.savedFormData.caBankingInfo?.bankDetails.forEach((bank: any) => {
        this.bankDetails.push(this.fb.group(bank));
      });
      this.savedFormData.tradeReference?.trades.forEach((trade: any) => {
        this.trades.push(this.fb.group(trade));
      });
      this.creditApplicationCanadaForm.patchValue(this.savedFormData);
      this.bankingInfoSign = this.savedFormData.caBankingInfo.caBankSignature
        ? `data:image/png;base64,${this.savedFormData.caBankingInfo.caBankSignature}`
        : null;
      this.bankingInfoSigned = this.bankingInfoSign !== null;
      this.acknowledgmentSign = this.savedFormData.acknowledge
        .caAcknowledgeSignature1
        ? `data:image/png;base64,${this.savedFormData.acknowledge.caAcknowledgeSignature1}`
        : null;
      this.acknowledgmentSigned =  this.acknowledgmentSign !== null;
    }
  }

  get trades(): UntypedFormArray {
    if (this.country == 'US')
      return this.creditApplicationForm.get('trades') as UntypedFormArray;
    return this.creditApplicationCanadaForm.get(
      'tradeReference.trades'
    ) as UntypedFormArray;
  }

  removeTrade(i: number) {
    this.trades.removeAt(i);
  }

  newTrade(): UntypedFormGroup {
    return this.fb.group({
      tradeBusinessName: [''],
      tradeAddress: [''],
      tradePhone: [
        '',
        [
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      tradeEmail: ['', Validators.email],
      tradeId: 0,
    });
  }

  addTrade() {
    this.trades.push(this.newTrade());
  }

  download(data: any, fileName: any) {
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement('a');
    const filename = fileName;
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }

  get ownerShip(): UntypedFormArray {
    return this.creditApplicationCanadaForm.get(
      'businessInfo.ownerShip'
    ) as UntypedFormArray;
  }

  newOwner(): UntypedFormGroup {
    return this.fb.group({
      ownerName: [''],
      ownershipPercentage: [''],
      ownershipId: 0,
    });
  }

  addOwner() {
    this.ownerShip.push(this.newOwner());
  }

  getFOPInitiationInfo() {
    this._fopHttpService
      .GetFOPInitiationInfo(this.fopId)
      .subscribe((response: any) => {
        this.country = response.response.country;
      });
  }

  get bankDetails(): UntypedFormArray {
    return this.creditApplicationCanadaForm.get(
      'caBankingInfo.bankDetails'
    ) as UntypedFormArray;
  }

  newBank(): UntypedFormGroup {
    return this.fb.group({
      caBankName: ['', Validators.required],
      caBankBranch: [''],
      caBankAddressLine1: [''],
      caBankAddressLine2: [''],
      caBankCity: [''],
      caBankState: [''],
      caBankZip: [''],
      caBankAccountNumber: ['', Validators.required],
      caTypeOfAccount: ['', Validators.required],
      caBankContact: [''],
      caBankPhone: [
        '',
        [
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(10),
          Validators.maxLength(17),
        ],
      ],
      caBankEmail: ['', Validators.email],
      creditBankId: 0,
    });
  }

  addBank() {
    this.bankDetails.push(this.newBank());
  }

  onUploadDocChange(event: any, inputName: string) {
    if (event.target.files.length > 0) {
      try {
        const file = event.target.files[0];
        let fileType = event.target.files[0].type;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let fileData: any = reader.result;
          fileData = fileData.replace(`data:${fileType};base64,`, '');
          this.uploadDocChangeSwitchInput(event, inputName, fileData);
        };
      } catch (err) {
        console.log(err);
      }
    }
  }

  uploadDocChangeSwitchInput(event: any, inputName: string, fileData: any) {
    switch (inputName) {
      case 'CreditUpload':
        this.creditUploadApplicationForm.patchValue({
          creditUploadDoc: fileData,
          creditUploadDocName: event.target.files[0].name,
        });
        break;
      case 'Signature':
        this.uploadDocChangeSwitchInputRefactored('Signature', fileData, event);
        break;
      case 'TradeReferenceFile':
        if (this.country == 'US') {
          this.creditApplicationForm.patchValue({
            tradeReferenceFile: fileData,
            tradeReferenceFileName: event.target.files[0].name,
          });
        } else if (this.country == 'CA') {
          this.creditApplicationCanadaForm.patchValue({
            tradeReference: {
              caTradeReferenceFile: fileData,
              tradeReferenceFileName: event.target.files[0].name,
            },
          });
        }
        break;
      case 'AcknowledgeSignature':
        this.uploadDocChangeSwitchInputRefactored(
          'AcknowledgeSignature',
          fileData,
          event
        );
        break;
      case 'UploadW9':
        if (this.creditFormType == 'UPLOAD' && this.country == 'US') {
          this.creditUploadApplicationForm.patchValue({
            uploadW9: fileData,
            uploadW9Name: event.target.files[0].name,
          });
        } else if (this.country == 'US') {
          this.creditApplicationForm.patchValue({
            uploadW9: fileData,
            uploadW9Name: event.target.files[0].name,
          });
        }
        break;
      case 'UploadPurchaseOrder':
        this.uploadDocChangeSwitchInputRefactored(
          'UploadPurchaseOrder',
          fileData,
          event
        );
        break;
      case 'FinancialStatement':
        this.creditApplicationCanadaForm.patchValue({
          businessInfo: {
            financialStatement: fileData,
            financialStatementName: event.target.files[0].name,
          },
        });
        break;
      case 'GstCertificate':
        this.creditApplicationCanadaForm.patchValue({
          taxInformation: {
            gstCertificateDocument: fileData,
            gstHstCertificateName: event.target.files[0].name,
          },
        });
        break;
      case 'QstCertificate':
        this.creditApplicationCanadaForm.patchValue({
          taxInformation: {
            qstCertificateDocument: fileData,
            qstCertificateName: event.target.files[0].name,
          },
        });
        break;
      case 'SalesTaxCertificate':
        this.creditApplicationCanadaForm.patchValue({
          taxInformation: {
            salesTaxCertificateDocument: fileData,
            salesTaxCertificateName: event.target.files[0].name,
          },
        });
        break;
      default:
        console.log('default');
        break;
    }
  }

  uploadDocChangeSwitchInputRefactored(type: any, fileData: any, event: any) {
    switch (type) {
      case 'AcknowledgeSignature':
        if (this.country == 'US') {
          this.creditApplicationForm.patchValue({
            acknowledgeSignature: fileData,
            acknowledgeSignatureName: event.target.files[0].name+'.png',
          });
        } else if (this.country == 'CA') {
          this.creditApplicationCanadaForm.patchValue({
            acknowledge: {
              caAcknowledgeSignature1: fileData,
              caAcknowledgeSignature1Name: event.target.files[0].name + '.png',
            },
          });
        }
        break;
      case 'Signature':
        if (this.country == 'US') {
          this.creditApplicationForm.patchValue({
            signature: fileData,
            signatureName: event.target.files[0].name + '.png',
          });
        } else if (this.country == 'CA') {
          this.creditApplicationCanadaForm.patchValue({
            caBankingInfo: {
              caBankSignature: fileData,
              caSignatrueName: event.target.files[0].name + '.png',
            },
          });
        }
        break;
      case 'UploadPurchaseOrder':
        if (this.creditFormType == 'UPLOAD') {
          this.creditUploadApplicationForm.patchValue({
            purchaseOrder: fileData,
            purchaseOrderName: event.target.files[0].name,
          });
        } else if (this.country == 'US') {
          this.creditApplicationForm.patchValue({
            purchaseOrder: fileData,
            purchaseOrderName: event.target.files[0].name,
          });
        } else if (this.country == 'CA') {
          this.creditApplicationCanadaForm.patchValue({
            documentsUpload: {
              purchaseOrder: fileData,
              purchaseOrderName: event.target.files[0].name,
            },
          });
        }
        break;
      default:
        break;
    }
  }

  getTemplate(certificateType: any) {
    this._fopHttpService
      .getCATaxInfoCertificateTemplates(certificateType)
      .subscribe((response: any) => {
        const data = response.taxCertificateDocument;
        const filename = response.taxCertificateName;
        this.download(data, filename);
      });
  }

  eSignOpen(eSignType: any) {
    this.eSignType = eSignType;
    this.displayDialog = true;
  }

  eSignClear(eSignType: any) {
    let event = {
      target: {
        files: [
          {
            name: '',
          },
        ],
      },
    };
    let fileData = '';
    if (eSignType === 'bankingInfo') {
      this.bankingInfoSigned = false;
      this.bankingInfoSign = null;
      this.uploadDocChangeSwitchInput(event, 'Signature', fileData);
    }
    if (eSignType === 'acknowledgmentSign') {
      this.acknowledgmentSigned = false;
      this.acknowledgmentSign = null;
      this.uploadDocChangeSwitchInput(event, 'AcknowledgeSignature', fileData);
    }
    this.displayDialog = false;
  }

  receiveSignedImage(signedImage: any) {
    let event = {
      target: {
        files: [
          {
            name: this.eSignType,
          },
        ],
      },
    };
    let fileData = signedImage.replace(`data:image/png;base64,`, '');
    if (this.eSignType === 'bankingInfo') {
      this.bankingInfoSigned = true;
      this.bankingInfoSign = signedImage;
      this.uploadDocChangeSwitchInput(event, 'Signature', fileData);
    }
    if (this.eSignType === 'acknowledgmentSign') {
      this.acknowledgmentSigned = true;
      this.acknowledgmentSign = signedImage;
      this.uploadDocChangeSwitchInput(event, 'AcknowledgeSignature', fileData);
    }
    this.displayDialog = false;
  }
}
