import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject,forkJoin } from 'rxjs';
import { FopHttpService } from 'src/app/services/fop-http.service';
import {
  ShippingPayload,
  ShippingRequirementsHelper,
} from './shipping.requirements.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipping-requirements',
  templateUrl: './shipping-requirements.component.html',
  styleUrls: ['./shipping-requirements.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ShippingRequirementsComponent implements OnInit, OnDestroy {
  _getDetailshipping:any = [];
  submitTriggered: Subject<string> = new Subject();
  userId = parseInt(sessionStorage.getItem('userId') + '');
  fopId: any;
  fopTypeId: any;
  requestTypes: any;
  disableSelectedRequest: boolean = false;
  @Input() selectedRequest: any;
  @Input() savedFormData: any;
  @Input() disableForm: any;
  @Output() closeFormEvent = new EventEmitter();
  @Output() setActiveItem = new EventEmitter();
  @Output() getExistingShippingData = new EventEmitter();
  mandatoryFlag = true;
  disableSubmit:boolean = false; 

  comments: string = '';
  basfCCfop: any;
  enduserFop: any;
  displayReadyToGoDialog: boolean = false;
  isContractIncluded:boolean=false;
  accountNumber: any;
  customerAccountSpecialist: any;
  customerAccountSpecialistDD: any;
  shippingRequestsList: any[] = [];
  shippingData: any;
  materialNumberList = [];
  companyCityStateOfShippingAddress: any[] = [];
  savedBulkTruckData: any[] = [];
  savedPackageTruckData: any[] = [];
  savedRailData: any[] = [];
  PENDING_WITH_CUSTOMER = 'Pending With Customer';
  COMMENTS_REQUIRED_TO_RESEND_FOR_CORRECTION =
    'COMMENTS_REQUIRED_TO_RESEND_FOR_CORRECTION';
  PLEASE_SELECT_ACCOUNT_NUMBER_AND_CUSTOMER_SPECIALIST =
    'PLEASE_SELECT_ACCOUNT_NUMBER_AND_CUSTOMER_SPECIALIST';
  constructor(
    private spinner: NgxSpinnerService,
    private _fopHttpService: FopHttpService,
    private _shippingHelper: ShippingRequirementsHelper,
    private translate: TranslateService
  ) {
    this.basfCCfop = sessionStorage.getItem('fop');
    this.enduserFop = sessionStorage.getItem('enduserFop');
    this.fopId = parseInt(sessionStorage.getItem('fopId') + '');
    this.translate.stream('ALL_FOP').subscribe((resp) => {
      this.initializeTabItems();
    });
    if (this.basfCCfop) {
      this.basfCCfop = JSON.parse(this.basfCCfop);
    }
    if (this.enduserFop) {
      this.enduserFop = JSON.parse(this.enduserFop);
    } else {
      this.getEnduserFop();
    }
    if (this.basfCCfop?.fopId) {
      this.fopTypeId = this.basfCCfop?.fopTypeId;
    } else {
      this.fopTypeId = this.enduserFop?.fopTypeId;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.isContractIncluded=this.enduserFop==null?this.basfCCfop.isContractIncluded:this.enduserFop.isContractIncluded;
    console.log(this.isContractIncluded,"check in shipping form")
    //this.getMaterial(); --blocked for forkjoin
    this.initializeTabItems();    

    this._shippingHelper.getShippingData().subscribe((data) => {
      this.shippingData = data;
    });

    //this.getSavedShippingData();--blocked for forkjoin
    //this.getCustomerAccountSpecialistData();--blocked for forkjoin
    //this.getCompnaystatecityFromShippingAddres();  --blocked for forkjoin
    //this._fopHttpService.getSavedMasterData(); --blocked for forkjoin

    //Forkjoin integration to avoid parallel running
    this._getDetailshipping = [
      this._fopHttpService.GetMaterils(this.fopId),      
      this._fopHttpService.getShippingReqData(this.fopId),
      this._fopHttpService.getCustomerAccountSpecialistData(this.fopId),
      this._fopHttpService.getCompnaystatecityFromShippingAddres(this.fopId),
      this._fopHttpService.getSavedMasterData() 
     ]; 

    forkJoin(this._getDetailshipping).subscribe((res:any)=>{
      if (!res[0]['isError']) {
        this.getMaterial(res[0])      
      }
     

     if (!res[1].isError) {
      this.getSavedShippingData(res[1])      
      }

      if (!res[2].isError) {
        this.getCustomerAccountSpecialistData(res[2])        
      }

      if (!res[3].isError) {
        this.getCompnaystatecityFromShippingAddres(res[3])        
      }

    }); 
    
    setTimeout(() => {
      this.spinner.hide();
      }, 12000);
  }

  initializeTabItems() {
    this.PENDING_WITH_CUSTOMER = this.translate.instant(
      'PENDING_WITH_CUSTOMER'
    );
    this.requestTypes = [
      { name: this.translate.instant('BULK_TRUCK_REQUEST'), value: 'BTR' },
      { name: this.translate.instant('PACKAGE_TRUCK_REQUEST'), value: 'PTR' },
      { name: this.translate.instant('RAIL_REQUEST'), value: 'RR' },
    ];
  }

  getCompnaystatecityFromShippingAddres(response:any) {
    // this._fopHttpService
    //   .getCompnaystatecityFromShippingAddres(this.fopId)
    //   .subscribe((response: any) => {
        console.log(response);

        if (!response.isError) {
          this.companyCityStateOfShippingAddress = response;
          console.log(this.companyCityStateOfShippingAddress);
        }
      //});
  }

  getSavedShippingData(response:any) {
    this.shippingRequestsList = [];
    // this._fopHttpService
    //   .getShippingReqData(this.fopId)
    //   .subscribe((response: any) => {
        const resp = response.response;
        if (!response.isError) {
          let basicFopTypeId = resp.basicFopTypeId;
          localStorage.setItem('basicFopTypeId', basicFopTypeId.toString());
          this._shippingHelper.sendShippingData(resp);
          if (resp.bulkTruckRequests) {
            this.getShippingList(resp.bulkTruckRequests, 'bulkrequest');
          }
          if (resp.packageTruckRequests) {
            this.getShippingList(resp.packageTruckRequests, 'packagerequest');
          }
          if (resp.railRequests) {
            this.getShippingList(resp.railRequests, 'railrequest');
          }
        }
      // });
  }

  getShippingList(list: any, flag: any) {
    list.forEach((rr: any) => {
      let mcp =
        flag == 'railrequest'
          ? (<any[]>rr.multiCompanyCityState).map((k) => k.city).join(' ')
          : (<any[]>rr.receivingContact.multiCompanyCityState)
              .map((k) => k.city)
              .join(' ');
      if (!mcp) {
        mcp =
          flag == 'railrequest'
            ? rr.destinationcity
            : rr.receivingContact.destinationcity;
      }
      if (flag == 'railrequest') {
        this.shippingRequestsList.push({
          type: 'RR',
          name: 'RR ' + mcp,
          savedFormData: rr,
        });
      } else if (flag == 'packagerequest') {
        this.shippingRequestsList.push({
          type: 'PTR',
          name: 'PTR ' + mcp,
          savedFormData: rr,
        });
      } else if (flag == 'bulkrequest') {
        this.shippingRequestsList.push({
          type: 'BTR',
          name: 'BTR ' + mcp,
          savedFormData: rr,
        });
      }
    });
  }

  getEnduserFop() {
    setTimeout(() => {
      this.enduserFop = sessionStorage.getItem('enduserFop');
      this.fopId = parseInt(sessionStorage.getItem('fopId') + '');
      if (this.enduserFop) {
        this.enduserFop = JSON.parse(this.enduserFop);
        this.fopTypeId = this.enduserFop?.fopTypeId;
      }
    }, 9000);
  }

  closeForm() {
    sessionStorage.removeItem('fop');
    sessionStorage.removeItem('fopId');
    sessionStorage.removeItem('enduserFop');
    this.closeFormEvent.emit();
  }

  onSelectRequestChange(event: any) {
    this.selectedRequest = event.value;
    sessionStorage.setItem('shipSelectedReq', this.selectedRequest);
  }

  addShippingRequest(type: 'BTR' | 'PTR' | 'RR') {
    this.shippingRequestsList.push({
      type: type,
      name: type,
      savedFormData: {},
    });
  }

  getCustomerAccountSpecialistData(response:any) {
    // this._fopHttpService
    //   .getCustomerAccountSpecialistData(this.fopId)
    //   .subscribe((response: any) => {
        if (response.isError) {
          console.log(response);
        }
        this.customerAccountSpecialistDD = response.response;        
      // });
  }

  reviewFopRequest(type: string) {
    // this.spinner.show();
    let request = {
      fopId: this.fopId,
      fopTypeId: this.fopTypeId,
      submitFlag: 'Resend To Customer',
      reviewComments: '',
      submittedBy: this.userId
    };
    if (type === 'confirm') {
      request.submitFlag = 'Complete';
      if (this.comments.length > 1) {
        request.reviewComments = this.comments;
      } else {
        request.reviewComments = 'Reviewed FOP; Marked as Complete.';
      }
    } else {
      request.submitFlag = 'Resend To Customer';
      if (this.comments.length < 1) {
        this._fopHttpService.displayToastr({
          isError: true,
          response: this.translate.instant(
            this.COMMENTS_REQUIRED_TO_RESEND_FOR_CORRECTION
          ),
        });
        return;
      }
      request.reviewComments = this.comments;

      // save comment to show in history and comments tab
      let requestObj = {
        fop_Id: this.fopId,
        comment_Id: 0,
        comments: this.comments,
        parentId: 0,
        user: this.userId.toString(),
        date: new Date(),
        repliesCount: 0,
      };
      this._fopHttpService
        .CreateComments(requestObj)
        .subscribe((response: any) => {
          if (response.isError) {
            this._fopHttpService.displayToastr(response);
          }
        });
    }
    this._fopHttpService
      .reviewFopRequest(request)
      .subscribe((response: any) => {
        if (!response['isError']) {
          this.basfCCfop.status = type === 'confirm' ? 'Approved' : 'Draft';
          sessionStorage.setItem('fop', JSON.stringify(this.basfCCfop));
        }
        // this.spinner.hide();
        this._fopHttpService.displayToastr(response);
      });
  }

  readyToGo() {
    if (!this.accountNumber || !this.customerAccountSpecialist) {
      this._fopHttpService.displayToastr({
        isError: true,
        response: this.translate.instant(
          this.PLEASE_SELECT_ACCOUNT_NUMBER_AND_CUSTOMER_SPECIALIST
        ),
      });
      return;
    }

    const params = {
      fopId: this.fopId,
      fopTypeId: this.fopTypeId,
      accountNo: this.accountNumber,
      accountSpecialist: this.customerAccountSpecialist.customerSpecialistName,
      accountSpecialistId: this.customerAccountSpecialist.customerSpecialistId,
      userId: this.userId,
    };
    this._fopHttpService.readyToGo(params).subscribe((response: any) => {
      if (!response['isError']) {
        this.basfCCfop.status = 'Ready To GO';
        sessionStorage.setItem('fop', JSON.stringify(this.basfCCfop));
      }
      this.displayReadyToGoDialog = false;
      //this.spinner.hide();
      this._fopHttpService.displayToastr(response);
    });
  }
  getMaterial(response:any) {
    // this._fopHttpService.GetMaterils(this.fopId).subscribe((response: any) => {
      if (!response['isError']) {
        this.materialNumberList = response;
      }
    // });
  }
  closeDialog() {
    this.displayReadyToGoDialog = false;
  }

  activeIndex(value: any) {
    this.setActiveItem.emit(value);
  }

  preSubmitFop() {
    this.disableSubmit=true;
    this.saveShippingRequirementsData(true);
  }
  GetExistingShippingData() {
    this.getExistingShippingData.emit();
  }

  LoadBulkTruckData(value: any) {
    this.savedBulkTruckData.push(value);
  }
  LoadPkgTruckData(value: any) {
    this.savedPackageTruckData.push(value);
  }
  LoadRailData(value: any) {
    this.savedRailData.push(value);
  }

  saveShippingRequirementsData(runSubmit?: any) {
    this.savedBulkTruckData = [];
    this.savedPackageTruckData = [];
    this.savedRailData = [];
    this.spinner.show();

    this.submitTriggered.next('');

     setTimeout(() => {
      let requestData = {} as ShippingPayload;
      requestData.bulkTruckRequests = [];
      requestData.railRequests = [];
      requestData.packageTruckRequests = [];
    requestData.bulkTruckRequests = this.savedBulkTruckData;
      requestData.packageTruckRequests = this.savedPackageTruckData;
      requestData.railRequests = this.savedRailData;

      // const requestData: ShippingPayload = this.shippingData as ShippingPayload;
      this._fopHttpService
        .saveShippingData(this.fopId, requestData)
        .subscribe((resp: any) => {         
          this._fopHttpService.displayToastr(resp);
          requestData.bulkTruckRequests = [];
          requestData.railRequests = [];
          requestData.packageTruckRequests = [];
          if (runSubmit) {
            this.submitFOP();
          }
          this._fopHttpService.getShippingReqData(this.fopId).subscribe((data:any) => {
            this.getSavedShippingData(data);
          });         
        });   

        this.spinner.hide();
      }, 12000);  
  }

  deleteForm(inx: number) {
    this.shippingRequestsList.splice(inx, 1);
  }
  ngOnDestroy(): void {
    this.saveShippingRequirementsData();
  }

  submitFOP() {
    this._fopHttpService
      .submitFOP(
        this.enduserFop?.fopId,
        this.enduserFop?.fopTypeId,
        this.userId
      )
      .subscribe((resp: any) => {
        if (!resp['isError']) {
          this.enduserFop.status = 'Submitted';
          sessionStorage.setItem('enduserFop', JSON.stringify(this.enduserFop));
          this.closeForm();
        }
        else{
          this.mandatoryFlag = true;
        }   

        this._fopHttpService.displayToastr(resp);
        this.disableSubmit = false;
      });
  }
}
