const BaseUrl = 'https://customersetup-dev.basf.com/api';

export const environment = {
  production: false,

  // Admin
  getMasterData: `${BaseUrl}/Admin/GetMasterData`,
  getCustomerAccountSpecialistData: `${BaseUrl}/Admin/GetCustomerAccountSpecialistData`,
  getAdminDropDownData: `${BaseUrl}/Admin/GetAdminDropDownData`,
  getAllCustomersByUserId: `${BaseUrl}/Admin/GetAllCustomersByUserId`,
  getCustomer: `${BaseUrl}/Admin/GetCustomer`,
  postCreateOrUpdateCustomerData: `${BaseUrl}/Admin/CreateOrUpdateCustomerData`,
  postCreateorUpdateUser: `${BaseUrl}/Admin/CreateorUpdateUser`,
  getAllUser: `${BaseUrl}/Admin/GetAllUser`,
  getUserDetailsBasedOnId: `${BaseUrl}/Admin/GetUserDetailsBasedOnId`,
  getAllMasterData: `${BaseUrl}/Admin/GetGridMasterData`,
  getMasterDropDown: `${BaseUrl}/Admin/GetMasterDropDown`,
  getAllCountries: `${BaseUrl}/Admin/GetAllCountries`,
  postCreateorUpdateCountry: `${BaseUrl}/Admin/CreateOrUpdateCountry`,
  deleteCountry: `${BaseUrl}/Admin/DeleteCountry`,
  postCreateOrUpdateMaster: `${BaseUrl}/Admin/CreateOrUpdateMaster`,
  deleteMasterData: `${BaseUrl}/Admin/DeleteMasterData`,
  postCreateOrUpdateCustomerAccount: `${BaseUrl}/Admin/CreateOrUpdateCustomerAccount`,
  deleteCustomerAccount: `${BaseUrl}/Admin/DeleteCustomerAccount`,
  getAllCustomerAccount: `${BaseUrl}/Admin/GetAllCustomerAccount`,
  getCustomerAccountById: `${BaseUrl}/Admin/GetCustomerAccountById`,
  deleteUser: `${BaseUrl}/Admin/DeleteUser`,

  getAllDivisions: `${BaseUrl}/Admin/GetAllDivisions`,
  postCreateorUpdateDivision: `${BaseUrl}/Admin/CreateOrUpdateDivision`,
  deleteDivision: `${BaseUrl}/Admin/DeleteDivision`,

  GetAllSbuData: `${BaseUrl}/Admin/GetAllSbuData`,
  CreateOrUpdateSbu: `${BaseUrl}/Admin/CreateOrUpdateSbu`,
  DeleteSBU: `${BaseUrl}/Admin/DeleteSBU`,

  updateContractDocumentMaster:`${BaseUrl}/Admin/UpdateContractDocument`,
  getContractDocumentMaster:`${BaseUrl}/Admin/GetContractDocumentMaster`,
  updateDocwithSignatorydetails:`${BaseUrl}/Admin/UpdateDocwithSignatorydetails`,

  getAllDocuments: `${BaseUrl}/Admin/GetAllDocuments`,
  postCreateorUpdateDocument: `${BaseUrl}/Admin/CreateOrUpdateDocument`,
  deleteDocument: `${BaseUrl}/Admin/DeleteDocument`,
  
  // Export all forms and documents
  exportAll: `${BaseUrl}/ExportFop/GetFormDownload`,

  // Customer setup
  GetContractTypes: `${BaseUrl}/Admin/GetContractTypes`,
  saveCustomerSetup: `${BaseUrl}/CustomerSetup/SaveCustomerSetup`,
  getNewCustomerSetupData: `${BaseUrl}/CustomerSetup/CustomerInfo`,
  getCustomerInfobySoldToId: `${BaseUrl}/CustomerSetup/CustomerInfobySoldToId`,
  GetMaterils: `${BaseUrl}/CustomerSetup/GetMaterils`,

  // Credit
  getCreditApplicationData: `${BaseUrl}/Credit/CreditInfo`,
  saveCreditSetup: `${BaseUrl}/Credit/SaveCreditSetup`,
  saveCreditUploadSetup: `${BaseUrl}/Credit/SaveCreditUploadSetup`,
  saveCACreditSetup: `${BaseUrl}/Credit/SaveCACreditSetup`,
  getCACreditApplicationData: `${BaseUrl}/Credit/GetCACreditInfo`,
  getCATaxInfoCertificateTemplates: `${BaseUrl}/Credit/GetCATaxInfoCertificateTemplates`,

  // Shipping-Requirements
  getShippingReqData: `${BaseUrl}/ShippingRequirement/GetShippingDetails`,
  saveShippingData: `${BaseUrl}/ShippingRequirement/SaveShippingData`,
  getCompnaystatecityFromShippingAddres: `${BaseUrl}/ShippingRequirement/GetCompnaystatecityFromShippingAddres`,
  deleteShippingData: `${BaseUrl}/ShippingRequirement/deleteShippingRequest`,
  // sendBulkTruckData: `${BaseUrl}/ShippingRequirement/SaveBulkTruckShippingDataUS`,
  // sendPackageTruckData: `${BaseUrl}/ShippingRequirement/SavePackageTruckShippingData`,
  // sendRailRequestData: `${BaseUrl}/ShippingRequirement/SaveRailRequestShippingData`,
  // ExportShippingFormToExcel: `${BaseUrl}/ShippingRequirement/GetShippingFormDownload`,

  // CustomerFOP
  getFopHistory: `${BaseUrl}/CustomerFOP/GetFopHistory`,
  getFOPGridData: `${BaseUrl}/CustomerFOP/GetFOPGridData`,
  getCustomerDetails: `${BaseUrl}/CustomerFOP/GetCustomerDetails`,
  initiateNewFOP: `${BaseUrl}/CustomerFOP/InitiateNewFOP`,
  initiateFOP: `${BaseUrl}/CustomerFop/InitiateFOP`,
  reviewFopRequest: `${BaseUrl}/CustomerFOP/ReviewFopRequest`,
  submitFOP: `${BaseUrl}/CustomerFOP/SubmitFOP`,
  readyToGo: `${BaseUrl}/CustomerFOP/ReadytoGoNotification`,
  GetFOPInitiationInfo: `${BaseUrl}/CustomerFOP/GetFOPInitiationInfo`,
  GetFopComments: `${BaseUrl}/CustomerFop/GetFopComments`,
  CreateComments: `${BaseUrl}/CustomerFop/CreateComments`,
  
  // Customer Contract Document
  getCustomerContractDocument:`${BaseUrl}/CustomerFop/GetCustomerContractDocument`,
  updateCustomerContractDocument:`${BaseUrl}/CustomerFop/UpdateCustomerContractDocument`,


  // Tax Exempt
  SaveTaxExceptCertificate: `${BaseUrl}/TaxExempt/SaveTaxExceptCertificate`,
  GetTaxExemptionCertificate: `${BaseUrl}/TaxExempt/GetTaxExemptionCertificate`,
  GetTaxExemptionTemplateCertificate: `${BaseUrl}/TaxExempt/GetTaxExemptionTemplateCertificate`,

  //Saml API's
  getSamlURL: `${BaseUrl}/GetSamlURL`,
  getUserByEmail: `${BaseUrl}/GetuserByEmail`,
};
